import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`This lesson provides an introduction to parts of speech.`}</p>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <ul>
      <li parentName="ul">{`identify parts of speech`}</li>
      <li parentName="ul">{`identify two popular tagsets`}</li>
      <li parentName="ul">{`apply tests for identifying a part of speech`}</li>
    </ul>
    <h1 {...{
      "id": "background",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#background",
        "aria-label": "background permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background`}</h1>
    <p><em parentName="p">{`What is a part of speech`}</em>{`?`}</p>
    <p>{`A part of speech (POS) tag is the category that reflects a word or token's grammatical properties (ex. noun, verb, adjective, adverb, etc.).`}</p>
    <p>{`Considered together, parts of speech are a shallow dip into syntax, or the underlying grammatical structure of sentences.  `}</p>
    <p>{`Think for a moment how certain kinds of words cannot appear together:`}</p>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "cross mark"
        }}>{`❌`}</span>{` `}</undefined><em parentName="p">{`dog the`}</em><br parentName="p"></br>{`
`}<undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "check mark"
        }}>{`✔️`}</span>{` `}</undefined><em parentName="p">{`the dog`}</em><br parentName="p"></br>{`
`}<undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "cross mark"
        }}>{`❌`}</span>{` `}</undefined><em parentName="p">{`cat any`}</em><br parentName="p"></br>{`
`}<undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "check mark"
        }}>{`✔️`}</span>{` `}</undefined><em parentName="p">{`any cat`}</em>{`  `}</p>
    <p>{`From those examples, we might generalize to sequences of part of speech tags:`}</p>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "cross mark"
        }}>{`❌`}</span>{` `}</undefined><code parentName="p" {...{
        "className": "language-text"
      }}>{`NOUN DET`}</code><br parentName="p"></br>{`
`}<undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "check mark"
        }}>{`✔️`}</span>{` `}</undefined><code parentName="p" {...{
        "className": "language-text"
      }}>{`DET NOUN`}</code></p>
    <p>{`Even from just a few examples, we can see that certain tag sequences are grammatical and others are ungrammatical.`}</p>
    <p>{`The grammaticality of a particular tag sequence can vary from language to language.  Moreover, certain sequences of tags are more `}<em parentName="p">{`frequent`}</em>{` in a particular language. For example, in Russian, adjectives can occur after a noun but more commonly precede nouns.`}</p>
    <p>{`Interestingly, sentences can be `}<em parentName="p">{`grammatical`}</em>{` without conveying any real meaning.  Here is a famous example for English:`}</p>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "check mark"
        }}>{`✔️`}</span>{` colorless green ideas sleep furiously`}</undefined></p>
    <p>{`There are some parts of speech that appear to be universal (present in all attested natural languages), and others that are shared by groups of languages.  The category boundaries between different parts of speech can also vary from language to language.  For example, in English, there is a clear separation between adverbs and adjectives, but in other languages the boundary can be fuzzy or thin.`}</p>
    <h2 {...{
      "id": "open-class-vs-closed-class-categories",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#open-class-vs-closed-class-categories",
        "aria-label": "open class vs closed class categories permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Open class vs closed class categories`}</h2>
    <p>{`One broad distinction we can make when discussing parts of speech is open vs closed classes.  Open classes are those parts of speech which more easily accept new members.  For example, we frequently see new proper nouns on a daily basis in the form of company or product names.  Some of those brands have even `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Conversion_(word_formation)#Verbification",
        "target": "_self",
        "rel": "nofollow"
      }}>{`been "verbed"`}</a>{`.`}</p>
    <p>{`In contrast, function words such as determiners, conjunctions, auxilliary verbs, and adpositions (prepositions in English) comprise a `}<em parentName="p">{`closed`}</em>{` class of tags that resist being extended.  When was the last time you heard a new conjunction or preposition being used? `}</p>
    <h1 {...{
      "id": "what-are-part-of-speech-tags-used-for",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#what-are-part-of-speech-tags-used-for",
        "aria-label": "what are part of speech tags used for permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h1">{`What are part of speech tags used for?`}</em></h1>
    <p>{`Part of speech tags can serve as useful features when training statistical models for ...`}</p>
    <ul>
      <li parentName="ul">{`syntactic parsing`}</li>
      <li parentName="ul">{`chunking (shallow parsing)`}</li>
      <li parentName="ul">{`named entity recognition and information extraction (events, relations, etc.)`}</li>
      <li parentName="ul">{`sentiment analysis`}</li>
      <li parentName="ul">{`authorship detection`}</li>
      <li parentName="ul">{`etc.`}</li>
    </ul>
    <p>{`If part of speech tags are inaccurate, the models that use them as features are unlikely to be reliable.  This is an important consideration when applying tools trained on one domain (ex. news stories) to something quite different (chat room logs).`}</p>
    <h1 {...{
      "id": "tagsets",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#tagsets",
        "aria-label": "tagsets permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tagsets`}</h1>
    <p>{`If you need to develop a part of speech tagger for a particular language or domain (ex. legal documents), you will likely need to gather and annotate data. `}</p>
    <p>{`Let's examine two popular tagsets...`}</p>
    <h2 {...{
      "id": "penn-treebank-ptb",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#penn-treebank-ptb",
        "aria-label": "penn treebank ptb permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Penn `}<a parentName="h2" {...{
        "href": "https://en.wikipedia.org/wiki/Treebank",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Treebank`}</a>{` (PTB)`}</h2>
    <p>{`Devised in the 1990s under the direction of `}<a parentName="p" {...{
        "href": "https://www.cis.upenn.edu/~mitch/",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Mitch Marcus`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.ling.upenn.edu/~beatrice/",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Beatrice Santorini`}</a>{`, the University of Pennsylvania's Treebank tagset is an English-centric set of 36 tags that includes granular information related to tense and plurality.  For details, including a full list of tags and their descriptions, see the annotation manual:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://repository.upenn.edu/handle/20.500.14332/7512",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://repository.upenn.edu/handle/20.500.14332/7512`}</a>{` (see the PDF link)`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://web.archive.org/web/19970614072242if_/http://www.cis.upenn.edu/~treebank/tokenization.html",
        "target": "_self",
        "rel": "nofollow"
      }}>{`See this link`}</a>{` for information on the tokenization stategies used in the `}<a parentName="p" {...{
        "href": "https://web.archive.org/web/19970614160127/http://www.cis.upenn.edu/~treebank/",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Penn Treebank project`}</a>{`.`}</p>
    <h2 {...{
      "id": "universal-parts-of-speech-upos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#universal-parts-of-speech-upos",
        "aria-label": "universal parts of speech upos permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Universal Parts of Speech (UPOS)`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://universaldependencies.org/",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Universal Dependencies project`}</a>{` aims to provide `}</p>
    <blockquote>
      <p>
[...] a universal inventory of categories and guidelines to facilitate consistent annotation of similar constructions across languages, while allowing language-specific extensions when necessary.
      </p>
      <footer>
  From the introduction to <cite><a href="https://universaldependencies.org/docs/introduction.html">universaldependencies.org</a></cite>
      </footer>
    </blockquote>
    <p>{`Compared to the PTB tagset, the universal part of speech (UPOS) tagset is more coarse-grained.  For instance, verbs in UPOS have no tag subtypes corresponding to different tenses.  `}</p>
    {/* | TAG | DESCRIPTION | EXAMPLES |
     |-----|-------------|----------|
     |     |             |          | */}
    <p>{`For more information, including language-specific examples of each tag, see the documentation:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://universaldependencies.org/u/pos/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://universaldependencies.org/u/pos/`}</a></li>
    </ul>
    <p>{`For descriptions and examples of the tags applied to English, see the the following link:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://universaldependencies.org/en/pos/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://universaldependencies.org/en/pos/`}</a></li>
    </ul>
    <h1 {...{
      "id": "determining-a-part-of-speech",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#determining-a-part-of-speech",
        "aria-label": "determining a part of speech permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Determining a part of speech`}</h1>
    <p>{`When annotating parts of speech, there may be times when you enounter an unfamiliar token.`}</p>
    <h2 {...{
      "id": "substitution-test",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#substitution-test",
        "aria-label": "substitution test permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Substitution test`}</h2>
    <p>{`The `}<em parentName="p">{`substitution test`}</em>{` can be used to help determine the correct part of speech to assign a token.  Consider the following sentence:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Robert `}<strong parentName="p">{`frambled`}</strong>{` quickly.`}</p>
    </blockquote>
    <p>{`What is the part of speech for the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Pseudoword",
        "target": "_self",
        "rel": "nofollow"
      }}>{`psuedoword`}</a>{` `}<em parentName="p">{`frambled`}</em>{`?  Subtitute a word with a part of speech you know.  For example, using the UPOS tagset, you know that "the" is always labeled as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DET`}</code>{` in English:`}</p>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "cross mark"
        }}>{`❌`}</span>{` `}</undefined><em parentName="p">{`Robert `}<strong parentName="em">{`the`}</strong>{` quickly.`}</em></p>
    <p>{`That doesn't make sense.  Let's try a verb:`}</p>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "expressionless face"
        }}>{`😑`}</span>{` `}</undefined><em parentName="p">{`Robert `}<strong parentName="em">{`walk`}</strong>{` quickly.`}</em></p>
    <p>{`Warmer...`}</p>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "grinning face with smiling eyes"
        }}>{`😄`}</span>{` `}</undefined><em parentName="p">{`Robert `}<strong parentName="em">{`walked`}</strong>{` quickly.`}</em></p>
    <p>{`Bingo!  Using the more granular PTB tagset, we'd label `}<strong parentName="p">{`frambled`}</strong>{` as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`VBD`}</code>{`.  With the more coarse-grained UPOS, it would simply be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`VERB`}</code>{`.`}</p>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "eyes"
        }}>{`👀`}</span>{` When discussing parsing in LING 539, we'll see the same sort of test applied to constituency.`}</undefined></p>
    <h1 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next steps`}</h1>
    <h2 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h2>
    <ul>
      <li parentName="ul">{`Describe some of the differences between PTB and UPOS`}</li>
      <li parentName="ul">{`What is the PTB tag for past tense verbs?`}</li>
      <li parentName="ul">{`Give an example of a closed class part of speech and word that belongs to that category`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      